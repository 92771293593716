import React, { useState, useEffect } from 'react';
import './quote.css';

const Hadith = () => {
  const [hadith, setHadith] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState('');

  const fetchHadith = () => {
    fetch('/malik.json')
      .then(response => response.json())
      .then(data => {
        const date = new Date();
        const index = date.getDate() % data.hadiths.length;
        setHadith(data.hadiths[index]);
      })
      .catch(error => console.error("Error fetching the Hadith:", error));
  };

  useEffect(() => {
    fetchHadith();

    const interval = setInterval(() => {
      const now = new Date();
      const nextMidnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        0, 0, 0, 0
      );
      const timeDiff = nextMidnight - now;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='box'>
      <h1 className='title-container'> HADITH OF THE DAY</h1>
      <div className='container'>
      {hadith && (
        <>
          <p className='hadith-text'>
            "{hadith.english.text}"
          </p>
          <p className='hadith-text'>
            {hadith.arabic}
          </p>
        </>
      )}
      </div>
      <div className='update-container'>
        <h2>Next update in: {timeRemaining}</h2>
      </div>
    </div>
  );
};

export default Hadith;